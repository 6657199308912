<template>
  <b-form class="mt-0">
    <validation-observer ref="userValidation" v-slot="{ invalid }">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Email address"
            label-for="username"
          >
            <validation-provider #default="{ errors }" name="Username" rules="required" :disabled="!isNewUser">
              <b-form-input
                v-model="username"
                name="username"
                placeholder="(not set)"
                type="email"
                :readonly="!isNewUser"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="isNewUser"
            label="Password"
            label-for="password"
          >
            <validation-provider #default="{ errors }" name="Password" rules="required">
              <b-form-input
                v-model="password"
                name="password"
                :placeholder="'(Required)'"
                type="password"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div>
              <small class="text-muted">Password Policy</small>
              <ul>
                <li class="text-muted" v-for="policy in passwordPolicy" :key="policy">
                  {{ policy }}
                </li>
              </ul>
            </div>
          </b-form-group>
          <div class="d-flex justify-content-between w-100">
            <b-form-group
              v-if="!isNewUser"
              label="Reset Password"
              label-for="reset-password"
            >
              <div class="d-inline-flex justify-content-between w-100">
                <b-form-input
                  id="reset-password"
                  v-model="resetPassword"
                  placeholder="············"
                  type="password"
                />
                <b-button
                  :disabled="!resetPassword"
                  class="ml-50"
                  @click="resetPasswordClicked"
                >
                  Reset
                </b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>

        <b-col sm="6">
          <div class="d-inline-flex">
            <b-form-group
              label="Avatar"
              label-for="fileSelect"
            >
              <b-link id="fileSelect" href="#" class="avatar-link-box" @click="showFileUpload">
                <b-avatar icon="CameraIcon" size="72px" :src="avatarFileResult" />
              </b-link>
              <b-form-file
                id="fileInput"
                v-model="avatarFile"
                :state="Boolean(avatarFile)"
                style="display: none"
                accept="image/*"
                @change="imageFileChanged"
              />
            </b-form-group>
          </div>
          <b-button v-if="avatarFileResult" variant="outline-secondary" size="sm" @click="deletePhotoClicked">
            Clear avatar
          </b-button>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="First name"
            label-for="first-name"
          >
            <validation-provider #default="{ errors }" name="First Name" rules="required" :disabled="!isNewUser">
              <b-form-input
                v-model.trim="firstName"
                name="firstName"
                placeholder="(not set)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Last name"
            label-for="last-name"
          >
            <validation-provider #default="{ errors }" name="Last Name" rules="required" :disabled="!isNewUser">
              <b-form-input
                v-model.trim="lastName"
                name="lastName"
                placeholder="(not set)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Job Title"
            label-for="account-jobTitle"
          >
            <b-form-input
              v-model="jobTitle"
              name="jobTitle"
              placeholder="(not set)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Additional options"
            label-for="enabled-checkbox"
          >
            <b-form-checkbox
              id="enabled-checkbox"
              v-model="enabled"
              class="mr-2"
              name="enabled-checkbox"
            >
              User Login Enabled
            </b-form-checkbox>
            <b-form-checkbox
              v-if="isNewUser"
              id="send-verification-email-checkbox"
              v-model="sendVerificationEmail"
              class="mr-2"
              name="send-verification-email-checkbox"
            >
              Send verification e-mail
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between">
        <div>
          <b-button
            v-if="!isNewUser"
            class="mr-auto ml-0"
            variant="outline-danger"
            :disabled="invalid"
            @click="deleteUser"
          >
            Delete User
          </b-button>
        </div>
      </div>
    </validation-observer>
  </b-form>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { getBlobUrlFromApiData } from '@core/utils/utils'

export default {
  name: 'AdminUserDetails',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    user: { type: Object, default: null },
  },
  data() {
    return {
      avatarFile: null,
      avatarFileResult: '',
      sendVerificationEmail: true,
      password: '',
      resetPassword: '',
      required,
      jobTitle: this.user.jobTitle,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      enabled: this.user.enabled,
      username: this.user.username,
      passwordPolicy: {},
    }
  },
  computed: {
    isNewUser() {
      return this.user.id === 'new'
    },
  },
  async mounted() {
    this.passwordPolicy = await this.$coreService.adminCliApi.getPasswordPolicy()
    if (!this.isNewUser) {
      try {
        const config = { responseType: 'blob' }
        const imageResponse = await this.$coreService.get(`/auth/user/${this.user.id}/avatar`, config)
        this.avatarFileResult = getBlobUrlFromApiData(imageResponse.data)
      } catch {
        console.log('No avatar found for user')
      }
    }
  },
  deactivated() {
    URL.revokeObjectURL(this.avatarFileResult)
  },
  methods: {
    async deleteUser() {
      const opts = {
        centered: true,
        okVariant: 'danger',
        okTitle: 'Delete User',
      }
      const userConfirmation = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${this.user.firstName} ${this.user.lastName}?`, opts)
      if (userConfirmation) {
        await this.$coreService.userManagementApi.deleteUser(this.user.id)
        this.$bvModal.hide('add-user-modal')
      }
    },
    async resetPasswordClicked() {
      try {
        const response = await this.$coreService.userManagementApi.resetPassword(this.user.id, this.resetPassword)
        this.toast('Temporary password has been set', 'CheckIcon', 'success')
      } catch {
        this.toast('Temporary password failed', 'AlertCircleIcon', 'danger')
      }
    },
    showFileUpload() {
      document.getElementById('fileInput').click()
    },
    imageFileChanged(e) {
      if (e.target.files.length > 0) {
        this.avatarFileResult = URL.createObjectURL(e.target.files[0])
        // eslint-disable-next-line prefer-destructuring
        this.avatarFile = e.target.files[0]
      }
    },
    async saveAvatar() {
      if (this.avatarFile !== null) {
        const formData = new FormData()
        formData.append('file', this.avatarFile)
        await this.$coreService.post(`/auth/user/${this.user.id}/avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }
    },
    async save() {
      if (this.isNewUser) {
        return this.saveNewUser()
      }
      return this.saveEditedUser()
    },
    async saveNewUser() {
      const valid = await this.$refs.userValidation.validate()
      if (!valid) return valid
      if (this.isNewUser) {
        const newUser = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.username,
          job_title: this.jobTitle || '',
          password: this.password,
          enabled: this.enabled,
          send_email_verification: this.sendVerificationEmail,
        }
        const newUserResponse = await this.$coreService.usersManagementApi.createUser(newUser)
        // set the userID now so that the avatar can be uploaded
        this.user.id = newUserResponse.id
        await this.saveAvatar()
        await this.$store.dispatch('auth/updateUser', this.user)
      }
      return true
    },
    async saveEditedUser() {
      const valid = await this.$refs.userValidation.validate()
      if (!valid) return valid
      if (!this.isNewUser) {
        this.user.attributes ??= {}
        this.user.attributes.jobTitle = this.jobTitle
        this.user.jobTitle = this.jobTitle
        const editedUser = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.username,
          job_title: this.jobTitle || '',
          password: this.password,
          enabled: this.enabled,
        }
        const updatedUser = { ...this.user, ...editedUser }
        try {
          const newUserResponse = await this.$coreService.userManagementApi.updateUser(updatedUser)
          // set the userID now so that the avatar can be uploaded
          this.user.id = newUserResponse.id
          await this.saveAvatar()
          await this.$store.dispatch('auth/updateUser', updatedUser)
        } catch (e) {
          this.toast(`Failed to save user: ${e}`, 'WarningIcon', 'danger')
        }
      }
      return true
    },
    toast(title = 'OK', icon = 'EditIcon', variant = 'success') {
      this.$toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    },
    close() {
      this.$emit('closed', '')
    },
    async deletePhotoClicked() {
      if (this.avatarFileResult) {
        await this.$coreService.userManagementApi.deleteUserAvatar(this.user.id)
        this.avatarFileResult = ''
      }
    },
  },
}
</script>

<style scoped>
.avatar-link-box {
    display: block;
    width: 75px;
    height: 75px;
    margin: 16px;
}

.avatar-link-box:hover {
    opacity: 0.6
}
</style>
