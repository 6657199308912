<template>
  <div>
    <b-card-actions
      ref="adminUsersTable"
      title="Users"
      no-body
      action-refresh
      :show-loading="loading"
      @refresh="refresh"
    >
      <div class="mb-1">
        <b-form inline class="ml-1" style="gap: 1rem" @submit="() => {}">
          <b-input-group
            class="input-group-merge"
          >
            <b-input
              id="user-search"
              v-model="allUserSearchText"
              class="form-control-merge"
              name="user-search"
              placeholder="Search users"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
              />
            </b-input-group-append>
          </b-input-group>
          <b-button data-cy="btn-user-sync" variant="outline-secondary" @click="syncAllPermissions" :disabled="isSyncing">
            <feather-icon icon="RefreshCwIcon" class="text-primary" />
            Sync Permissions
          </b-button>
          <b-button data-cy="btn-user-add" variant="outline-success" @click="addUserClicked">
            <feather-icon icon="PlusIcon" class="text-success" />
            Create User
          </b-button>
        </b-form>
      </div>
      <b-table
        :items="users"
        :fields="userFields"
        :sort-by.sync="userSortBy"
        :sort-desc.sync="userSortDesc"
        responsive="sm"
        selectable
        select-mode="single"
        selected-variant="dark"
        :current-page="currentUserPage"
        :per-page="perPage"
        @row-clicked="onUserRowClicked"
      >
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>
        <template #cell(enabled)="data">
          <b-form-checkbox :checked="data.value" disabled />
        </template>
      </b-table>
      <b-pagination
        v-model="currentUserPage"
        :total-rows="userRowCount"
        :per-page="perPage"
        class="ml-2"
      />
    </b-card-actions>
    <div v-if="selectedUser">
      <admin-user-groups-roles :key="selectedUser.id" :selected-user="selectedUser" @closed="onUserClosed" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AdminUserGroupsRoles from './AdminUserEdit.vue'

const scrollToEnd = () => {
  setTimeout(() => window.scrollTo(9999, 9999), 100)
}

export default {
  name: 'AdminUsersTable',
  components: {
    AdminUserGroupsRoles,
    BCardActions,
  },
  data() {
    return {
      allUserSearchText: '',
      userFields: [
        {
          key: 'enabled',
          sortable: true,
          thStyle: {
            width: '5rem',
          },
        },
        {
          key: 'avatar',
          sortable: false,
          label: '',
          title: 'Photo',
          thStyle: {
            width: '4rem',
          },
        },
        { key: 'username', sortable: true },
        { key: 'firstName', sortable: true },
        { key: 'lastName', sortable: true },
        { key: 'jobTitle', sortable: true },
      ],
      userSortBy: 'firstName',
      userSortDesc: false,
      selectedUser: null,
      currentUserPage: 1,
      perPage: 8,
      loading: false,
      isSyncing: false,
    }
  },
  computed: {
    ...mapGetters({
      allUsers: 'auth/allUsers',
      groups: 'auth/allGroups',
    }),
    users() {
      const predicate = p => p.toLowerCase().includes(this.allUserSearchText.toLowerCase())
      return this.allUsers.filter(u => this.allUserSearchText === ''
          || predicate(u.username) || predicate(u.firstName) || predicate(u.lastName))
    },
    userRowCount() {
      return this.users.length
    },
  },
  async mounted() {
    await this.refresh()
    await this.$store.dispatch('auth/getAllGroups')
    await this.$store.dispatch('auth/getAllRoles')
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'add-user-modal') {
        this.refresh()
      }
    })
  },
  methods: {
    async refresh() {
      this.loading = true
      await this.$store.dispatch('auth/getAllUsers')
      this.loading = false
    },
    addUserClicked() {
      this.selectedUser = { id: 'new', enabled: true }
      this.$nextTick(() => this.$bvModal.show('add-user-modal'))
    },
    async syncAllPermissions() {
      this.isSyncing = true
      // eslint-disable-next-line
      for (const u of this.allUsers) {
        // eslint-disable-next-line
        await this.$coreService.adminCliApi.migrateUserAdmin(u.username)
      }
      this.$toast({
        component: ToastificationContent,
        props: { title: 'All Users Synced', icon: 'InfoIcon', variant: 'success' },
      })
      this.isSyncing = false
    },
    onUserRowClicked(item) {
      if (item) {
        this.selectedUser = item
        // wait a tick til the selectedUser updates to allow the v-if through
        this.$nextTick(() => this.$bvModal.show('add-user-modal'))
      }
    },
    onUserClosed() {
      this.selectedUser = null
      this.$bvModal.hide('add-user-modal')
    },
  },
}
</script>
