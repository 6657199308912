<template>
  <b-modal
    id="add-user-modal"
    :title="isNewUser ? 'Create User' : 'Edit User'"
    size="lg"
    no-close-on-backdrop
  >
    <div class="mt-0">
      <h4 class="font-weight-bold">
        {{ selectedUser.firstName }} {{ selectedUser.lastName }}</h4>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="User Details" active>
            <admin-user-details ref="adminUserDetails" :key="selectedUser.id" :user="selectedUser" @closed="onDetailsClosed" />
          </b-tab>
          <b-tab title="Groups and Roles">
            <b-form class="mt-0">
              <b-form-row>
                <b-col class="col-md-5">
                  <b-form-group label="Available Groups">
                    <b-form-select v-model="selected" :options="availableGroups" multiple :select-size="6" />
                  </b-form-group>
                </b-col>
                <b-col class="col-md-2">
                  <div class="flex-column btn-col">
                    <b-button class="m-1" variant="outline-primary" @click="addToGroupClicked">
                      <font-awesome-icon :icon="['fa', 'fa-arrow-right']" />
                    </b-button>
                    <b-button class="m-1" variant="outline-primary" @click="removeFromGroupClicked">
                      <font-awesome-icon :icon="['fa', 'fa-arrow-left']" />
                    </b-button>
                  </div>
                </b-col>
                <b-col class="col-md-5">
                  <b-form-group label="User's Current Groups">
                    <b-form-select v-model="selected" :options="userGroups" multiple :select-size="6" />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>
            <b-form class="mt-0">
              <b-form-row>
                <b-col class="col-md-5">
                  <b-form-group label="Available Roles">
                    <b-form-select v-model="selected" :options="availableRoles" multiple :select-size="6" />
                  </b-form-group>
                </b-col>
                <b-col class="col-md-2">
                  <div class="flex-column btn-col">
                    <b-button class="m-1" variant="outline-primary" @click="addToRoleClicked">
                      <font-awesome-icon :icon="['fa', 'fa-arrow-right']" />
                    </b-button>
                    <b-button class="m-1" variant="outline-primary" @click="removeFromRoleClicked">
                      <font-awesome-icon :icon="['fa', 'fa-arrow-left']" />
                    </b-button>
                  </div>
                </b-col>
                <b-col class="col-md-5">
                  <b-form-group label="User's Current Roles">
                    <b-form-select v-model="selected" :options="userRoles" multiple :select-size="6" />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <template #modal-footer>
      <b-button variant="secondary" @click="onDetailsClosed">
        Cancel
      </b-button>
      <b-button data-cy="btn-user-save-new" variant="success" @click="saveAll">
        {{ isNewUser ? 'Create User' : 'Update User' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import difference from 'lodash/difference'
import AdminUserDetails from '@/auth/views/admin/AdminUserDetails.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AdminUser',
  components: { AdminUserDetails },
  props: {
    selectedUser: Object,
  },
  data() {
    return {
      selected: ['user1'],
      options: [],
      allGroups: [],
      allRoles: [],
      userGroups: [],
      userGroupsInitial: [],
      userRoles: [],
      userRolesInitial: [],
    }
  },
  computed: {
    availableGroups() {
      return this.allGroups.filter(ag => !this.userGroups.map(ug => ug.value).includes(ag.value))
    },
    availableRoles() {
      return this.allRoles.filter(ag => !this.userRoles.map(ug => ug.value).includes(ag.value))
    },
    isNewUser() {
      return this.selectedUser.id === 'new'
    },
  },
  async mounted() {
    this.allGroups = this.$store.state.auth.allGroups.map(g => ({ value: g.id, text: g.name }))
    this.allRoles = this.$store.state.auth.allRoles.map(g => ({ value: g.id, text: g.name }))
    if (this.selectedUser.id !== 'new') {
      const userGroupResponse = await this.$coreService.userManagementApi.getUserGroups(this.selectedUser.id)
      const userRoleResponse = await this.$coreService.userManagementApi.getUserRoles(this.selectedUser.id)
      this.userGroups = userGroupResponse.map(g => ({ value: g.id, text: g.name }))
      this.userRoles = userRoleResponse.map(g => ({ value: g.id, text: g.name }))
      this.userGroupsInitial = [...this.userGroups]
      this.userRolesInitial = [...this.userRoles]
    }
  },
  methods: {
    // add / remove from groups
    async addToGroupClicked() {
      const addingGroup = this.availableGroups.find(ag => ag.value === this.selected[0])
      if (addingGroup) {
        this.userGroups.push(addingGroup)
      }
    },
    async removeFromGroupClicked() {
      const removingGroup = this.userGroups.find(ug => ug.value === this.selected[0])
      if (removingGroup) {
        this.userGroups = this.userGroups.filter(ug => ug.value !== removingGroup.value)
      }
    },
    // add / remove from roles
    async addToRoleClicked() {
      const addingRoles = this.availableRoles.filter(ag => this.selected.includes(ag.value))
      if (addingRoles) {
        this.userRoles = this.userRoles.concat(addingRoles)
      }
    },
    async removeFromRoleClicked() {
      const removingRoles = this.userRoles.filter(ag => this.selected.includes(ag.value))
      if (removingRoles) {
        this.userRoles = this.userRoles.filter(ug => !removingRoles.find(ur => ur.value === ug.value))
      }
    },
    async saveAll() {
      let shouldCloseModal = false
      try {
        const result = await this.$refs.adminUserDetails.save()
        if (!result) {
          // form was not valid, don't close modal or save anything else
          this.$toast({
            component: ToastificationContent,
            props: { title: 'Error Saving User', icon: 'EditIcon', variant: 'danger' },
          })
          console.error('User edit form not valid', result)
          return
        }
        shouldCloseModal = true
        await Promise.all(difference(this.userGroupsInitial, this.userGroups).map(rg => this.$coreService.userManagementApi.removeUserGroup(this.selectedUser.id, rg.value)))
        await Promise.all(difference(this.userGroups, this.userGroupsInitial).map(rg => this.$coreService.userManagementApi.addGroupToUser(this.selectedUser.id, rg.value)))
        const addRoles = difference(this.userRoles, this.userRolesInitial)
        if (addRoles) {
          await this.$coreService.userManagementApi
            .addUserRoles(this.selectedUser.id, addRoles.map(ag => ag.text))
        }
        const removeRoles = difference(this.userRolesInitial, this.userRoles)
        if (removeRoles) {
          await this.$coreService.userManagementApi
            .removeUserRoles(this.selectedUser.id, removeRoles.map(ag => ag.text))
        }
        this.$toast({
          component: ToastificationContent,
          props: { title: 'User Saved', icon: 'EditIcon', variant: 'success' },
        })
      } catch (e) {
        const errorObj = e
        console.log('Error saving user', e.response, errorObj)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error Saving User',
            icon: 'EditIcon',
            variant: 'danger',
            text: e.response?.data?.detail || e.body?.detail,
          },
        })
      }
      if (shouldCloseModal) {
        this.$nextTick(() => {
          this.$bvModal.hide('add-user-modal')
          this.$store.dispatch('auth/getAllUsers')
        })
      }
    },
    onDetailsClosed() {
      this.$emit('closed')
    },
    async deleteUser() {
      const opts = {
        centered: true,
        okVariant: 'danger',
      }
      const response = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${this.user.firstName} ${this.user.lastName}?`, opts)
      if (response) {
        await this.$coreService.userManagementApi.deleteUser(this.user.id)
      }
    },
    // end of methods
  },
}
</script>

<style scoped>
.btn-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.btn-close {
    margin: 0.2em 0.5em 0 0;
    padding: 0.2em;
    position: absolute;
    right: 0;
    top: 0;
    font-size: xx-large;
    z-index: 9999;
}
</style>
